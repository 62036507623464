<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Postcode</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Country"
                    label="Country"
                    v-model="country.selected"
                    @change="execFilter('country')"
                    :items="country.items"
                    item-text="name"
                    item-value="CountryCode"
                    :rules="country.rule"
                    :loading="country.loader"
                    :disabled="country.disable"
                    hide-details
                    autocomplete="off"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All State"
                    label="State"
                    v-model="state.selected"
                    @change="execFilter('state')"
                    :items="state.items"
                    item-text="name"
                    item-value="StateCode"
                    :rules="state.rule"
                    :loading="state.loader"
                    :disabled="state.disable"
                    hide-details
                    autocomplete="off"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All City"
                    label="City"
                    v-model="city.selected"
                    @change="execFilter('city')"
                    :items="city.items"
                    item-text="name"
                    item-value="CityCode"
                    :rules="city.rule"
                    :loading="city.loader"
                    :disabled="city.disable"
                    hide-details
                    autocomplete="off"
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('postcode_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Postcode
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span v-if="componentLoader" class="text-body-2">View {{items[0].row}} - {{items[items.length - 1].row}} of {{total_records}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                        sort-by="CreatedDateTime"
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CountryCode}}</td>
                            <td>{{props.item.CountryName}}</td>
                            <td>{{props.item.StateCode}}</td>
                            <td>{{props.item.StateName}}</td>
                            <td>{{props.item.CityCode}}</td>
                            <td>{{props.item.CityName}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('postcode_update')">
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'update')">{{props.item.Postcode}}</a>
                                </template>
                                <span>Update Postcode</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                <template v-slot:activator="{ on }">
                                <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'view')">{{props.item.Postcode}}</a>
                                </template>
                                <span>View Postcode</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            <td>{{props.item.UpdatedDateTime}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></edit>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreatePostcode';
import edit from '.././modals/UpdatePostcode';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';

export default {
    name: 'Postcode',
    title: 'Postcode',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Administration',
                disabled: true,
                href: '#',
                },

                {
                text: 'Location',
                disabled: true,
                href: '#',
                },

                {
                text: 'Postcode',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '2%' },
                { text: 'Country Code', value: 'CountryCode', width: '2%' },
                { text: 'Country Name', value: 'CountryName', width: '4%' },
                { text: 'State Code', value: 'StateCode', width: '2%' },
                { text: 'State Name', value: 'StateName', width: '4%' },
                { text: 'City Code', value: 'CityCode', width: '2%' },
                { text: 'City Name', value: 'CityName', width: '8%' },
                { text: 'Postcode', value: 'Postcode', width: '8%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Updated Date', value: 'UpdatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                //{ text: 'Action', value: 'action', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            total_records: null,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                readonly: true,
                allInfo: [],
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            country: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            state: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            city: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            componentLoader: false,
        }
    },

    components: {create, edit, alert, alertbox2},

    async created(){
        this.getCountryFull();
        this.getStateFull();
        this.getCityFull();
        this.getPostcode();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getPostcode();
        }
        },
    },

    methods: {
        async getPostcode(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("listPostcode", {search: this.search});
                let response2 = await this.$store.dispatch("listCountry", {search: ''});
                let response3 = await this.$store.dispatch("listState", {search: ''});
                let response4 = await this.$store.dispatch("listCity", {search: ''});

                this.services = response;
                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.record.length;

                for(var i=0; i<dt.length; i++){
                    let ct = response2.data.record.find(item => item.CountryCode == dt[i].CountryCode);
                    let st = response3.data.record.find(item => item.StateCode == dt[i].StateCode);
                    let pc = response4.data.record.find(item => item.CityCode == dt[i].CityCode);
                    dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdatedDateTime = dt[i].UpdatedDateTime === null ? "N/A" : moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].CountryName = ct?.CountryName || 'N/A';
                    dt[i].StateName = st?.StateName || 'N/A';
                    dt[i].CityName = pc?.CityName || 'N/A';

                    let arrlocationitems = [
                        {name: 'country', truth: this.country.selected == '' ? false : true, selected: dt[i].CountryCode},
                        {name: 'state', truth: this.state.selected == '' ? false : true, selected: dt[i].StateCode},
                        {name: 'city', truth: this.city.selected == '' ? false : true, selected: dt[i].CityCode}
                    ];

                    let arrlocationitemsselected = arrlocationitems.filter(i => i.truth == true);

                    let listcsccode = arrlocationitemsselected;
                    dt[i].CountryStateCityCode = listcsccode;
                    //replace(/\s/g,'') remove any literal whitespace
                }

                let self = this;
                if (this.country.selected || this.state.selected || this.city.selected){
                let arrlocation = [
                    {name: 'country', truth: this.country.selected == '' ? false : true, selected: this.country.selected},
                    {name: 'state', truth: this.state.selected == '' ? false : true, selected: this.state.selected},
                    {name: 'city', truth: this.city.selected == '' ? false : true, selected: this.city.selected}
                ];

                let arrlocationselected = arrlocation.filter(i => i.truth == true);

                const objectsEqual = (o1, o2) => 
                typeof o1 === 'object' && Object.keys(o1).length > 0 
                    ? Object.keys(o1).length === Object.keys(o2).length 
                        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
                    : o1 === o2;

                let items = dt.filter(item => {
                    return objectsEqual(item.CountryStateCityCode, arrlocationselected);
                    });

                    dt = items;
                }

                for(var i=0; i<dt.length; i++){
                    dt[i].row = i + 1;
                }

                this.total_records = dt.length;
                var size = 100; var arrayOfArrays = [];
                for (var i=0; i<dt.length; i+=size) {
                    arrayOfArrays.push(dt.slice(i,i+size));
                }

                this.items = arrayOfArrays[this.page - 1];
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                this.componentLoader = true;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getCountryFull(){
            let list = [];
            this.country.items = [];
            try{
                this.country.loader = true;
                this.country.disable = true;
                let response = await this.$store.dispatch("listCountryAll");
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.country.items.push({name: "All Country", CountryCode: ""});
                    this.country.itemsFull.push({name: "All Country", CountryCode: ""});
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CountryCode} - ${dt[i].CountryName}`;
                        this.country.items.push({name: dt[i].name, CountryCode: dt[i].CountryCode});
                        this.country.itemsFull.push({name: dt[i].name, CountryCode: dt[i].CountryCode});
                    }
                }

                this.country.selected = this.country.items[0].CountryCode;

                this.country.loader = false;
                this.country.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getStateFull(){
            let list = [];
            this.state.items = [];
            this.state.selected = "";
            try{
                this.state.loader = true;
                this.state.disable = true;

                let self = this;
                if (this.country.selected){
                    let state = this.state.itemsFull.filter(item => {
                    return item.CountryCode == self.country.selected;
                    });

                    this.state.items.push({name: "All State", StateCode: ""});

                    for(var i=0; i<state.length; i++){
                        state[i].name = `${state[i].StateCode} - ${state[i].StateName}`;
                        this.state.items.push({name: state[i].name, StateCode: state[i].StateCode});
                    }
                }

                else{
                    let response = await this.$store.dispatch("listState", {search: ''});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.state.items.push({name: "All State", StateCode: ""});
                        this.state.itemsFull.push({name: "All State", StateCode: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].StateCode} - ${dt[i].StateName}`;
                        this.state.items.push({name: dt[i].name, StateCode: dt[i].StateCode});
                        this.state.itemsFull.push(dt[i]);
                    }

                    this.state.selected = this.state.items[0].StateCode;
                    }
                }

                this.state.loader = false;
                this.state.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCityFull(typ){
            let list = [];
            this.city.items = [];
            this.city.selected = "";
            try{
                this.city.loader = true;
                this.city.disable = true;

                let self = this;
                if (typ == 'country' && this.country.selected){
                    let city = this.city.itemsFull.filter(item => {
                    return item.CountryCode == self.country.selected;
                    });

                    this.city.items.push({name: "All City", CityCode: ""});

                    for(var i=0; i<city.length; i++){
                        city[i].name = `${city[i].CityCode} - ${city[i].CityName}`;
                        this.city.items.push({name: city[i].name, CityCode: city[i].CityCode});
                    }
                }

                else if (typ == 'state' && this.state.selected){
                    let city = this.city.itemsFull.filter(item => {
                    return item.StateCode == self.state.selected;
                    });

                    this.city.items.push({name: "All City", CityCode: ""});

                    for(var i=0; i<city.length; i++){
                        city[i].name = `${city[i].CityCode} - ${city[i].CityName}`;
                        this.city.items.push({name: city[i].name, CityCode: city[i].CityCode});
                    }
                }

                else {
                    let response = await this.$store.dispatch("listCity", {search: ''});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.city.items.push({name: "All City", CityCode: ""});
                        this.city.itemsFull.push({name: "All City", CityCode: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CityCode} - ${dt[i].CityName}`;
                        this.city.items.push({name: dt[i].name, CityCode: dt[i].CityCode});
                        this.city.itemsFull.push(dt[i]);
                    }

                    this.city.selected = this.city.items[0].CityCode;
                    }
                }

                this.city.loader = false;
                this.city.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addPostcode(v){
            this.btn_loading = true;
            this.tableloading = true;
            try{
                this.swal.scolor = 'green';
                let req = {countrycode: v[0].selected, statecode: v[1].selected, citycode: v[2].selected, postcode: v[3].postcode};
                let response = await this.$store.dispatch("createPostcode", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    await this.getPostcode();
                    this.swal.notification = true;
                    this.swal.scolor = 'green';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: Postcode Created!`;
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This postcode is already created`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
        },

        async cityRemove(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("deleteCity", {citycode: this.ma.modalInfo.CityCode})
                if (response.data.code === 'AP000'){
                        await this.getPostcode();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: City has been removed!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to remove. City is currently being used!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async postcodeUpdate(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("updatePostcode", {countrycode: v[0].selected, statecode: v[1].selected, citycode: v[2].selected, postcode: v[3].postcode})
                if (response.data.code === 'AP000'){
                        await this.getPostcode();

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Postcode has been updated!`;
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Unable to update. Postcode is currently being used!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin Profile", params: {id: row.userID}, query: {email: row.email}});
        },

        viewSOC(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Admin SOC", params: {id: row.userID}, query: {email: row.email}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addPostcode(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.postcodeUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.cityRemove();
                }
            }
        },

        async execFilter(type){
            if (type == 'country'){
                await this.getStateFull();
                await this.getCityFull('country');
                await this.searchTimeOut();
            }

            if (type == 'state'){
                await this.getCityFull('state');
                await this.searchTimeOut();
            }

            if (type == 'city'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getPostcode();
          }, 800);
        }
    }
}
</script>